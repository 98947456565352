/**
 * Adobe Recommendations compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import RecommendationsClient from '@magento/recommendations-js-sdk';

import ProductListQuery from 'Query/ProductList.query';
import getStore from 'SourceUtil/Store';
import { getIndexedProduct } from 'Util/Product';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import { POSITION_ABOVE_CONTENT, POSITION_BELLOW_CONTENT } from '../component/Recommendations/Recommendations.config';
import { waitForCallback } from './wait';

export const ONE_DAY_IN_MINS = 86400;
export const DEFAULT_UNITS = {
    [POSITION_ABOVE_CONTENT]: [],
    [POSITION_BELLOW_CONTENT]: []
};

/** @namespace Scandiweb/AdobeRecommendations/Util/Recommendations/processProductPrice */
export const processProductPrice = (currency = 'USD', price = 0) => ({
    default_price: {
        currency,
        value: parseFloat(price).toFixed(2) || 0
    },
    default_final_price: {
        currency,
        value: parseFloat(price).toFixed(2) || 0
    },
    default_final_price_excl_tax: {
        currency,
        value: parseFloat(price).toFixed(2) || 0
    },
    final_price: {
        currency,
        value: parseFloat(price).toFixed(2) || 0
    },
    regular_price: {
        currency,
        value: parseFloat(price).toFixed(2) || 0
    },
    regular_price_excl_tax: {
        currency,
        value: parseFloat(price).toFixed(2) || 0
    },
    final_price_excl_tax: {
        currency,
        value: parseFloat(price).toFixed(2) || 0
    },
    discount: { amount_off: 0, percent_off: 0 }
});

/** @namespace Scandiweb/AdobeRecommendations/Util/Recommendations/processProductImage */
export const processProductImage = ({ url: rawUrl = '' }) => {
    const { imagePlaceholderUrl } = getStore().getState().ConfigReducer;

    if (rawUrl) {
        const url = rawUrl.startsWith('//') ? `${window.location.protocol}${rawUrl}` : rawUrl;
        const { pathname, href } = new URL(url);

        return {
            path: pathname,
            url: href
        };
    }

    if (!imagePlaceholderUrl) {
        return {};
    }

    const { pathname, href } = new URL(imagePlaceholderUrl);

    return {
        path: pathname,
        url: href
    };
};

/** @namespace Scandiweb/AdobeRecommendations/Util/Recommendations/processProduct */
export const processProduct = (product, items = {}) => {
    const {
        prices: {
            minimum: { final = 0.00 } = {},
            maximum: { final: finalMax = 0.00 } = {}
        } = {},
        currency = 'USD',
        sku = null
    } = product;

    // eslint-disable-next-line no-prototype-builtins
    if (sku && items.hasOwnProperty(product.sku)) {
        const item = items[sku];

        const productUrlRaw = product.url || '';

        const productUrl = productUrlRaw.startsWith('//')
            ? `${window.location.protocol}${productUrlRaw}`
            : productUrlRaw;

        const { pathname: productPath } = new URL(productUrl);

        const newItem = getIndexedProduct(item);

        return ({
            ...product,
            ...newItem,
            url: productPath,
            image: processProductImage(product.image || {}),
            thumbnail: processProductImage(product.thumbnailImage || {}),
            small_image: processProductImage(product.smallImage || {}),
            id: product.sku
        });
    }

    const productUrlRaw = product.url || '';
    const productUrl = productUrlRaw.startsWith('//')
        ? `${window.location.protocol}${productUrlRaw}`
        : productUrlRaw;

    const { pathname: productPath } = new URL(productUrl);

    return ({
        ...product,
        price_range: {
            maximum_price: {
                ...processProductPrice(currency, finalMax)
            },
            minimum_price: {
                ...processProductPrice(currency, final)
            }
        },
        url: productPath,
        image: processProductImage(product.image || {}),
        thumbnail: processProductImage(product.thumbnailImage || {}),
        small_image: processProductImage(product.smallImage || {}),
        id: product.sku,
        mp_label_data: []
    });
};

/** @namespace Scandiweb/AdobeRecommendations/Util/Recommendations/processUnit */
export const processUnit = (unit, items) => {
    const { products } = unit;

    return {
        ...unit,
        // vvv maybe refactor to use just products
        products: products.reduce((acc, product) => {
            const { prices, sku } = product;

            if (!prices || !sku) {
                return acc;
            }

            return [...acc, processProduct(product, items)];
        }, [])
    };
};

/** @namespace Scandiweb/AdobeRecommendations/Util/Recommendations/processUntis */
export const processUntis = (units, items = {}) => (
    units.reduce((acc, unit) => {
        const { pagePlacement, products, unitId } = unit;

        if (products.length === 0 || unitId === undefined) {
            return acc;
        }

        // vvv Index by pagePlacement, process products
        const processedUnit = processUnit(unit, items);

        return {
            ...acc,
            // vvv Can not simply push, ref is used
            [pagePlacement]: [
                ...acc[pagePlacement],
                processedUnit
            ]
        };
    }, DEFAULT_UNITS)
);

/** @namespace Scandiweb/AdobeRecommendations/Util/Recommendations/getReccomendationUnits */
export const getReccomendationUnits = async () => {
    const { pageType } = window.magentoStorefrontEvents.context.getPage();
    const { environmentId } = window.magentoStorefrontEvents.context.getStorefrontInstance();

    if (!pageType || !environmentId) {
        // ^^^ Return default object if failed to load
        return DEFAULT_UNITS;
    }

    // vvv Options are not passed, assuming the client will read the from window.magentoStorefrontEvents
    const client = new RecommendationsClient();
    try {
        const { data: { results: newUnits } } = await client.fetchPreconfigured();
        // eslint-disable-next-line max-len
        const productsSkuArray = Array.from(new Set(newUnits.flatMap((obj) => obj.products.map((product) => product.sku))));
        const options = {
            isAdobeSenseiRecommendation: true,
            args: {
                filter: {
                    productsSkuArray,
                    pageSize: 2
                }
            }
        };
        const fetchedProductsQuery = ProductListQuery.getQuery(options);
        // eslint-disable-next-line max-len
        const fetchedProducts = await executeGet(prepareQuery(fetchedProductsQuery), 'AdobeSenseiRecomendationProducts', ONE_DAY_IN_MINS);
        const itemsArray = fetchedProducts?.products?.items || [];
        const items = itemsArray.reduce((obj, product) => {
            // eslint-disable-next-line no-param-reassign
            obj[product.sku] = product;
            return obj;
        }, {});

        return processUntis(newUnits, items);
    } catch (error) {
        return [];
    }
};

/** @namespace Scandiweb/AdobeRecommendations/Util/Recommendations/getPageBuilderReccomendationUnitById */
export const getPageBuilderReccomendationUnitById = async (rawUnitId) => {
    // vvv This is taken from original file
    // eslint-disable-next-line no-magic-numbers
    const unitId = rawUnitId.substring(0, 36);

    await waitForCallback(() => window.magentoStorefrontEvents.context.getStorefrontInstance()?.environmentId);
    const { environmentId } = window.magentoStorefrontEvents.context.getStorefrontInstance();

    if (!environmentId) {
        // ^^^ Return default object if failed to load
        return undefined;
    }

    const options = {
        unitId,
        pageType: 'PageBuilder'
    };

    const { alternateEnvironmentId } = getStore().getState().ConfigReducer;

    if (alternateEnvironmentId) {
        options.alternateEnvironmentId = alternateEnvironmentId;
    }

    const client = new RecommendationsClient(options);
    const res = await client.fetchPreconfigured(options);
    const { data: newUnit } = res;

    if (!newUnit) {
        return null;
    }

    const productsSkuArray = Array.from(new Set(newUnit.products.map((product) => product.sku)));
    const queryOptions = {
        isAdobeSenseiRecommendation: true,
        args: {
            filter: {
                productsSkuArray,
                pageSize: 2
            }
        }
    };
    const fetchedProductsQuery = ProductListQuery.getQuery(queryOptions);
    // eslint-disable-next-line max-len
    const fetchedProducts = await executeGet(prepareQuery(fetchedProductsQuery), 'AdobeSenseiRecomendationProducts', ONE_DAY_IN_MINS);
    const itemsArray = fetchedProducts?.products?.items || [];
    const items = itemsArray.reduce((obj, product) => {
        // eslint-disable-next-line no-param-reassign
        obj[product.sku] = product;
        return obj;
    }, {});

    return processUnit(newUnit, items);
};
