import {
    ProductListQuery as SourceProductListQuery
} from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Rapelli/Query/ProductList/Query/ProductListQuery */
export class ProductListQuery extends SourceProductListQuery {
    _getStockItemFields() {
        return [
            ...super._getStockItemFields(),
            'is_decimal'
        ];
    }

    // _getItemsField() {
    //     const result = super._getItemsField();
    //     // result.addFieldList(['customer_trade_unit']);
    //     result.addField(this._getB2bProductFields());
    //     return result;
    //     // return result;
    // }

    _getB2bProductFields() {
        return new Field('b2b_fields')
            .addFieldList([
                'line_rebate',
                'sales_unit_measure',
                'customer_trade_unit',
                'price',
                'old_price',
                'weight_multiply',
                'label_price'
            ]);
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        return [
            ...super._getProductInterfaceFields(isVariant, isForLinkedProducts),
            'contains_alcohol',
            'alcohol_percentage',
            'sales_text',
            'product_card_category',
            'customer_trade_unit',
            this._getPriceRangeField(),
            this._getB2bProductFields()
        ];
    }

    getB2BPdf(input) {
        return new Field('b2bPdfDownload')
            .addFieldList(this._getB2BPdfFields())
            .addArgument('input', 'B2bPdfInput', input);
    }

    _getB2BPdfFields() {
        return [
            'pdf'
        ];
    }

    getB2bImage(input) {
        return new Field('b2bImageDownload')
            .addFieldList(this._getB2bImageFields())
            .addArgument('input', 'B2bPdfInput', input);
    }

    _getB2bImageFields() {
        return [
            'zip'
        ];
    }
}

export default new ProductListQuery();
